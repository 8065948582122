import React, { useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Stack,
} from '@mui/material'

import styles from './styles'

const useStyles = makeStyles(styles)

const IMAGES = {
  homePickupDriver: {
    default: '/images/homepickup-driver.png',
    black: '/images/homepickup-driver-black.svg',
  },
  outletDriver: {
    default: '/images/outlet-driver.png',
    black: '/images/outlet-driver-black.svg',
  },
  iconLaundry: {
    default: '/images/laundry-color.svg',
    black: '/images/laundry-icon-black.svg',
  },
  iconCurtain: {
    default: '/images/curtain-color.svg',
    black: '/images/curtain-icon-black.svg',
  },
  preferred: '/images/preferred-driver.png',
}

const BookingInfo = ({ data }) => {
  const classes = useStyles()

  return data && data.map((item) => (
    <Stack
      key={item.id}
      direction="row"
      alignItems="center"
      className={classes.label}
    >
      {item.icon && (
        <img
          src={item.icon}
          alt="icon"
          width="22px"
          height="22px"
        />
      )}
      <Typography
        variant={item.variant || 'body2'}
        className={classes.label}
      >
        {item.info}
      </Typography>
    </Stack>
  ))
}

const DriverType = ({
  driver,
  color = 'default', // default / white
  label,
  labelVariant = 'body2',
  groupByDriver,
  tag,
  isPreferred,
}) => {
  const classes = useStyles()

  let src = IMAGES[driver.type][color]
  if (!src) src = IMAGES.homePickupDriver.default // default image

  const total = useMemo(() => {
    let totalCurtainTag = 0
    let totalOtherTag = 0
    let totalValue = 0
    let totalReserve = 0
    if (groupByDriver) {
      Object.entries(groupByDriver).forEach(([key, value]) => {
        if (driver.id === key) {
          const valueBooking = value.filter(item => item.isReserve === false)
          totalValue = valueBooking.length
          totalReserve = value.filter(item => item.isReserve === true).length

          // calculate at Timesheet tab only
          if (tag) {
            totalCurtainTag = valueBooking.filter(values => values.bookingTags.some(item =>
                item?.tag?.name.includes('Sofa (Dismantling)') ||
                item?.tag?.name.includes('Curtain (Dismantling)')
              )).length
            totalOtherTag = totalValue - totalCurtainTag
          }
        }
      })
    }
    return {
      curtain: String(totalCurtainTag),
      other: `${String(totalOtherTag)} (${totalReserve})`,
    }
  }, [groupByDriver, driver, tag])

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row">
        <BookingInfo
          data={[
            {
              id: 1,
              icon: src,
              info: label || `${driver.code} - ${driver.name}`,
              variant: labelVariant,
            },
          ]}
        />
        {isPreferred && (
          <img
            src={IMAGES.preferred}
            alt="Preferred driver"
            width="20px"
            height="20px"
            className={classes.preferred}
          />
        )}
      </Stack>
      {groupByDriver && tag && (
        <Stack direction="row">
          <BookingInfo
            data={[
              {
                id: 1,
                icon: IMAGES.iconCurtain[color],
                info: total.curtain,
              },
              {
                id: 2,
                icon: IMAGES.iconLaundry[color],
                info: total.other,
              },
            ]}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default DriverType
